<template>
  <LayoutSuperadmin>
    <ProductForm :id="id"></ProductForm>
  </LayoutSuperadmin>
</template>

<script>
import ProductForm from '@/components/Partial/Products/ProductForm/ProductForm.vue';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
export default {
  name: 'SuperProductEdit',
  components: { ProductForm, LayoutSuperadmin },

  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>
